import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {ValueChangedEvent} from 'devextreme/ui/html_editor';
import {ItemReorderedEvent} from 'devextreme/ui/list';
import notify from 'devextreme/ui/notify';
import {lastValueFrom, takeUntil} from 'rxjs';
import {BaseSubscriptionComponent} from 'src/app/base/base.suscription.component';
import {GeneratePdfItineraryByText} from 'src/app/models/requests/generate-pdf-itinerary-by-text';
import {GenerateReplyOrItineraryText} from 'src/app/models/requests/generate-reply-or-itinerary-text';
import {SearchSubticketTemplates} from 'src/app/models/requests/search-subticket-templates';
import {SubticketTemplate} from 'src/app/models/view-models/subticket-template';
import {HttpSubticketService} from 'src/app/services/http/http-subticket.service';
import {SharedDataService} from 'src/app/services/shared-data.service';
import {HttpFontService} from "../../services/http/http-font.service";
import {HttpItineraryTemplateService} from "../../services/http/http-itinerary.service";
import CustomStore from "devextreme/data/custom_store";
import {DxSelectBoxComponent} from "devextreme-angular";
import {CommonService} from "../../services/common.service";

@Component({
  templateUrl: './guest-requests-itinerary.component.html',
})
export class GuestRequestsItineraryComponent extends BaseSubscriptionComponent implements OnInit {
  public itineraryText = '';
  public editorToolbarItems;
  public subticketsTemplates: SubticketTemplate[] = [];
  public selectedTemplates: SubticketTemplate[] = [];

  public pdfLoading = false;
  public showPdfPreview = false;
  public previewUrl = '';
  protected itineraryTemplatesDatasource: { store: CustomStore<any, any> };

  @ViewChild(DxSelectBoxComponent)
  itineraryTemplateComponent: DxSelectBoxComponent;


  constructor(
    private router: Router,
    private httpSubticketService: HttpSubticketService,
    private sharedDataService: SharedDataService,
    private httpFontService: HttpFontService,
    private httpItineraryTemplateService: HttpItineraryTemplateService,
    private commonService: CommonService
  ) {
    super();
  }

  public async ngOnInit() {
    this.loadGridTemplates();
    this.editorToolbarItems = await this.initializeHtmlEditorToolbar();
    this.itineraryTemplatesDatasource = this.buildItineraryTemplatesDatasource();
  }

  public buildItineraryTemplatesDatasource() {
    return {
      store: new CustomStore({
        loadMode: "raw",
        load: () => lastValueFrom(this.httpItineraryTemplateService.getItineraryTemplates().pipe(takeUntil(this.destroyed)))
      })
    }
  }


  public onItineraryChanged(e: ValueChangedEvent): void {
    this.itineraryText = e.value;
  }

  public onSubticketsReordered(e: ItemReorderedEvent): void {
    const aux = this.subticketsTemplates[e.toIndex];
    this.subticketsTemplates[e.toIndex] = this.subticketsTemplates[e.fromIndex];
    this.subticketsTemplates[e.fromIndex] = aux;

    // Reorder selected subtickets
    const newSelectedSubtickets = [];
    this.subticketsTemplates.forEach((s) => {
      if (this.selectedTemplates.includes(s)) {
        newSelectedSubtickets.push(s);
      }
    });

    this.selectedTemplates = newSelectedSubtickets;
  }

  public backToMySubtickets(): void {
    this.router.navigate(['/guest-requests']);
    return;
  }

  public closeItineraryPreview(): void {
    this.showPdfPreview = false;
    this.pdfLoading = false;
    this.previewUrl = '';
  }

  public async printItinerary(): Promise<void> {
    const generateItineraryRequest: GeneratePdfItineraryByText = new GeneratePdfItineraryByText();
    generateItineraryRequest.ItineraryGenerated = this.itineraryText;
    generateItineraryRequest.ItineraryTemplateId = this.itineraryTemplateComponent.value

    this.showPdfPreview = true;
    this.pdfLoading = true;

    this.httpSubticketService
      .generatePdfItineraryByText(generateItineraryRequest)
      .pipe(takeUntil(this.destroyed))
      .subscribe({
        next: (byteArray) => {
          // TODO just pass a binary buffer from the start
          const blob = new Blob([this.commonService.base64ToArrayBuffer(byteArray)], {type: 'application/pdf'});
          this.previewUrl = window.URL.createObjectURL(blob);

          this.pdfLoading = false;
        },
        error: (error) => {
          notify(error, 'error');
          this.pdfLoading = false;
          this.previewUrl = '';
        },
        complete: () => console.info('complete'),
      });
  }

  public async generateItinerary(): Promise<void> {
    if (this.selectedTemplates.length == 0) {
      notify('Please, select some requests');
      return;
    }

    this.itineraryText = await this.generateItineraryText(this.selectedTemplates);
  }

  private async generateItineraryText(subtickets: SubticketTemplate[]): Promise<string> {
    return await lastValueFrom(
      this.httpSubticketService
        .generateReplyOrItineraryText(subtickets as GenerateReplyOrItineraryText[])
        .pipe(takeUntil(this.destroyed))
    );
  }

  private loadGridTemplates(): void {
    this.sharedDataService.sharedData.pipe(takeUntil(this.destroyed)).subscribe((subticketIds) => {
      if (subticketIds && subticketIds.length > 0) {
        const searchFilter = new SearchSubticketTemplates();
        searchFilter.SubticketIds = subticketIds;

        this.httpSubticketService
          .searchSubticketTemplatesBySubticketIds(searchFilter)
          .pipe(takeUntil(this.destroyed))
          .subscribe({
            next: (subtickets) => (this.subticketsTemplates = subtickets),
            error: (e) => console.error(e),
          });
      }
    });
  }

  // TODO move to a service, or better yet, a component
  private async initializeHtmlEditorToolbar() {
    const fonts = await lastValueFrom(
      this.httpFontService.getFonts().pipe(takeUntil(this.destroyed))
    );

    return [
      'background',
      'bold',
      'color',
      {
        name: 'font',
        acceptedValues: [
          null,
          'Arial',
          'Courier New',
          'Georgia',
          'Impact',
          'Tahoma',
          'Times New Roman',
          'Verdana',
        ].concat(fonts.map(font => font.FontFamily)),
      },
      'italic',
      'link',
      'image',
      {
        name: 'size',
        acceptedValues: ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'],
      },
      'strike',
      'subscript',
      'superscript',
      'underline',
      'blockquote',
      {
        name: 'header',
        acceptedValues: [false, 1, 2, 3, 4, 5],
      },
      'increaseIndent',
      'decreaseIndent',
      'orderedList',
      'bulletList',
      'alignLeft',
      'alignCenter',
      'alignRight',
      'alignJustify',
      'codeBlock',
      'variable',
      'separator',
      'undo',
      'redo',
      'clear',
    ]
  }

}
