import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';

import {Observable} from 'rxjs';
import {FontViewModel} from "../../models/view-models/font";

@Injectable({
  providedIn: 'root',
})
export class HttpFontService {
  private baseUrl = `${this.serverUrl}fonts`;

  constructor(private httpClient: HttpClient, @Inject('API_URL') public serverUrl: string) {}

  public getFonts(): Observable<FontViewModel[]> {
    return this.httpClient.get<FontViewModel[]>(`${this.baseUrl}`);
  }
}
