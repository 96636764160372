export enum SubticketFieldTypeEnum {
  NumberBox = 'dxNumberBox',
  TextBox = 'dxTextBox',
  TelBox = 'dxTelBox',
  DateTimeBox = 'dxDateBox',
  CheckBox = 'dxCheckBox',
  DateBox = 'dxDateOnlyBox',
  Currency = 'dxCurrencyBox',
  File = 'dxFileBox',
}

export const SubticketFieldTypeDescriptions = new Map<string, string>([
  [SubticketFieldTypeEnum.NumberBox, 'Number'],
  [SubticketFieldTypeEnum.TextBox, 'Text'],
  [SubticketFieldTypeEnum.TelBox, 'Phone Number'],
  [SubticketFieldTypeEnum.DateTimeBox, 'Date Time'],
  [SubticketFieldTypeEnum.CheckBox, 'Check Box'],
  [SubticketFieldTypeEnum.DateBox, 'Date'],
  [SubticketFieldTypeEnum.Currency, 'Currency'],
  [SubticketFieldTypeEnum.File, 'File'],
]);

export const SubticketFieldTypeConfig = new Map<string, any>([
  [
    SubticketFieldTypeEnum.Currency,
    { EditorOptions: { format: { type: 'currency', currency: 'CHF', precision: 2 } }, EditorType: 'dxNumberBox' },
  ],
  [SubticketFieldTypeEnum.CheckBox, { EditorOptions: { type: 'boolean' }, EditorType: 'dxCheckBox' }],
  [
    SubticketFieldTypeEnum.DateBox,
    { EditorOptions: { displayFormat: 'yyyy-MM-dd', type: 'date' }, EditorType: 'dxDateBox' },
  ],
  [
    SubticketFieldTypeEnum.DateTimeBox,
    { EditorOptions: { displayFormat: 'yyyy-MM-dd HH:mm:ss', type: 'datetime' }, EditorType: 'dxDateBox' },
  ],
  [
    SubticketFieldTypeEnum.TelBox,
    { EditorOptions: { mask: '+000 000-000-0000', type: 'string' }, EditorType: 'dxTextBox' },
  ],
  [SubticketFieldTypeEnum.NumberBox, { EditorOptions: null, EditorType: 'dxNumberBox' }],
  [SubticketFieldTypeEnum.TextBox, { EditorOptions: { type: 'string' }, EditorType: 'dxTextBox' }],
  [SubticketFieldTypeEnum.File, { EditorOptions: null, EditorType: null, UseCustomTemplate: true }],
]);
