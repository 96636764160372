import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';

import {Observable} from 'rxjs';
import {ItineraryTemplateViewModel} from "../../models/view-models/itinerary-template";

@Injectable({
  providedIn: 'root',
})
export class HttpItineraryTemplateService {
  private baseUrl = `${this.serverUrl}itinerary-templates`;

  constructor(private httpClient: HttpClient, @Inject('API_URL') public serverUrl: string) {
  }

  public getItineraryTemplates(): Observable<ItineraryTemplateViewModel[]> {
    return this.httpClient.get<ItineraryTemplateViewModel[]>(`${this.baseUrl}`);
  }
}
