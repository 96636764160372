import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

// TODO: Temporal Class. This class/interceptor should be removed when the use of "RemoteService" has been completely replaced. AuthInterceptor is responsible for replacing it.

@Injectable()
export class SetHeaderInterceptor implements HttpInterceptor {
  private readonly notSkipInterceptor = ['/Handovers/', '/Subtickets/', '/Codes/', '/Users/GetAgents/', '/Reservations/', 'itinerary-templates'];

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.notSkipInterceptor.some((substring) => req.url.includes(substring))) {
      return next.handle(req);
    }

    const cloneReq = req.clone({
      setHeaders: {
        'X-Skip-Auth-Interceptor': 'true',
      },
    });

    return next.handle(cloneReq);
  }
}
